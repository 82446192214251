.progress {
	background-color: #d8d8d8;
	border-radius: 20px;
	position: relative;
	height: 30px;
	width: 95%;
}

.progress-done {
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}
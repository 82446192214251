.custom-datepicker {
  height: 50px;
  width: 175px;
  max-width: auto;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.custom-datepicker:hover {
  background-color: #ffffff;
}

.custom-datepicker.active {
  border: 2px solid green;
}

